import { ControlPanelFields } from 'components/Main/Content/ControlPanel/types';
import { Sorting } from 'components/Main/Content/ControlPanel/Sorting/types';
import { OffersPages } from 'common/types/pages';
import { BuildOrderForm, FieldsBuildOrderForm, FormOfferKey } from 'lib/features/createOrder/types';
import { getOfferFormIds } from 'lib/features/createOrder/helpers';
import {
  round, getGb, getGbit, getMbit, getBytesFromGbit, getBytesFromGb, getBytesFromMbit, getHoursFromMinutes, getMinutesFromHours,
} from 'common/utils/slots';
import {
  OfferFilter, TOfferType, ConnectionArgs, TeeOfferFilter, OfferConfiguration, SortDir,
} from 'generated/types';
import getConfig from 'config';
import {
  FormControlPanel,
  FormFilters,
  FiltersFields,
  Form,
  Blocks,
  Availability,
  Variables,
  ExtPorts,
  FormFiltersCompute,
  FormFiltersSolutions,
  FormFiltersData,
  FormFiltersStorage,
  GetResetDataProps,
  Compatible,
  FiltersState,
  Offers,
} from './types';

const defaultFilters = {
  inactive: false,
  enabled: true,
};

export const getInitialControlPanel = (active: OffersPages): FormControlPanel => {
  switch (active) {
    case OffersPages.compute:
      return {
        [ControlPanelFields.sorting]: Sorting.availability,
      };
    default:
      return {
        [ControlPanelFields.sorting]: Sorting.newest,
      };
  }
};

export const getInitialFilters = (active: OffersPages): FormFilters => {
  const nonActiveFilters = {
    [FiltersFields.providers]: [],
  };

  const activeFilters = {
    [OffersPages.solutions]: {
      [FiltersFields.compatible]: Compatible.all,
      [FiltersFields.providers]: [],
      [FiltersFields.offerType]: TOfferType.Solution,
      [FiltersFields.offers]: Offers.sp,
    },
    [OffersPages.data]: {
      [FiltersFields.compatible]: Compatible.all,
      [FiltersFields.providers]: [],
      [FiltersFields.offerType]: TOfferType.Data,
      [FiltersFields.offers]: Offers.sp,
    },
    [OffersPages.storage]: {
      [FiltersFields.providers]: [],
      [FiltersFields.offerType]: TOfferType.Storage,
      [FiltersFields.offers]: Offers.sp,
    },
    [OffersPages.compute]: {
      [FiltersFields.offerType]: TOfferType.TeeOffer,
      [FiltersFields.providers]: [],
      [FiltersFields.options]: {
        externalPort: ExtPorts.any,
      },
      [FiltersFields.availability]: Availability.any,
    },
  };
  const result = activeFilters[active];
  return { ...(result ?? nonActiveFilters) };
};

export const getInitialValues = (
  active: OffersPages,
  props?: { filters?: FormFilters, controlPanel?: FormControlPanel },
): Form => {
  const { filters, controlPanel } = props || {};
  return {
    [Blocks.filters]: { ...(filters ?? getInitialFilters(active)) },
    [Blocks.controlPanel]: controlPanel || getInitialControlPanel(active),
  };
};

export const getResetData = (values: Form, props: GetResetDataProps): Form => {
  const { block, active } = props || {};
  switch (block) {
    case Blocks.filters:
      return { ...values, [block]: getInitialFilters(active) };
    case Blocks.controlPanel:
      return { ...values, [block]: getInitialControlPanel(active) };
    default:
      return getInitialValues(active);
  }
};

const getPreparedRange = (value?: number | null): [number] | [] => {
  return [typeof value === 'number' ? value : 0];
};

export const getPreparedFilterCommon = (form: Form) => {
  const { filters } = form || {};
  const { offerType } = filters || {};
  return {
    offerType,
  };
};

// const mapBuilderPages = {
//   [OffersPages.solutions]: 'solution',
//   [OffersPages.data]: 'data',
//   [OffersPages.compute]: 'tee',
//   [OffersPages.storage]: 'storage',
// };

// const getFilterRestrictions = (buildOrderForm?: BuildOrderForm, active?: OffersPages) => {
//   const pages = Object.values(OffersPages).filter((item) => item !== active).map((item) => mapBuilderPages[item]);
//   return buildOrderForm ? Object.entries(buildOrderForm).reduce((acc, [k, v]) => {
//     if (!pages.includes(k) || !v) return acc;
//     const value = Array.isArray(v) ? v.map((el: any) => el.value) : [v.value];
//     return [...acc, ...value];
//   }, [] as string[]) : [];
// };

const getPreparedFilterOffersSP = (active?: FormOfferKey) => {
  switch (active) {
    case FieldsBuildOrderForm.solution:
      return Object.keys(getConfig().NEXT_PUBLIC_SUPERPROTOCOL_OFFERS_SOLUTION);
    case FieldsBuildOrderForm.storage:
      return Object.keys(getConfig().NEXT_PUBLIC_SUPERPROTOCOL_OFFERS_STORAGE);
    case FieldsBuildOrderForm.data:
      return Object.keys(getConfig().NEXT_PUBLIC_SUPERPROTOCOL_OFFERS_DATA);
    default:
      return [];
  }
};

const getPreparedFilterOffersApproved = (active?: FormOfferKey) => {
  switch (active) {
    case FieldsBuildOrderForm.solution:
      return Object.keys(getConfig().NEXT_PUBLIC_APPROVED_OFFERS_SOLUTION);
    case FieldsBuildOrderForm.storage:
      return Object.keys(getConfig().NEXT_PUBLIC_APPROVED_OFFERS_STORAGE);
    case FieldsBuildOrderForm.data:
      return Object.keys(getConfig().NEXT_PUBLIC_APPROVED_OFFERS_DATA);
    default:
      return [];
  }
};

const getPreparedFilterOffers = (form: Form, field: FormOfferKey) => {
  const { filters } = (form || {});
  const { offers } = (filters || {}) as FormFiltersSolutions;

  switch (offers) {
    case Offers.sp:
      return { ...defaultFilters, ids: getPreparedFilterOffersSP(field) };
    case Offers.approved:
      return { ...defaultFilters, ids: getPreparedFilterOffersApproved(field) };
    case Offers.unmoderated:
      return { ...defaultFilters, excludeIds: [...getPreparedFilterOffersSP(field), ...getPreparedFilterOffersApproved(field)] };
    case Offers.inactive:
      return { ...defaultFilters, inactive: true };
    default:
      return {};
  }
};

export const getPreparedFilterSolution = (
  form: Form,
  buildOrderForm?: BuildOrderForm,
): OfferFilter => {
  const { filters } = (form || {});
  const { providers, compatible } = (filters || {}) as FormFiltersSolutions;
  const selectedOfferIds = getOfferFormIds(buildOrderForm?.[FieldsBuildOrderForm.data]);
  const filterOffers = getPreparedFilterOffers(form, FieldsBuildOrderForm.solution);
  return {
    ...(compatible !== Compatible.all && selectedOfferIds?.length ? { selectedOfferIds } : {}),
    ...(providers?.length ? { providerActionAccounts: providers } : {}),
    ...filterOffers,
  };
};

export const getPreparedFilterStorage = (
  form: Form,
): OfferFilter => {
  const { filters } = (form || {});
  const { providers } = (filters || {}) as FormFiltersStorage;
  const filterOffers = getPreparedFilterOffers(form, FieldsBuildOrderForm.storage);
  return {
    ...(providers?.length ? { providerActionAccounts: providers } : {}),
    ...filterOffers,
  };
};

export const getPreparedFilterData = (
  form: Form,
  buildOrderForm?: BuildOrderForm,
): OfferFilter => {
  const { filters } = (form || {});
  const { providers, compatible } = (filters || {}) as FormFiltersData;
  const selectedOfferIds = getOfferFormIds(buildOrderForm?.[FieldsBuildOrderForm.solution]);
  const filterOffers = getPreparedFilterOffers(form, FieldsBuildOrderForm.data);
  return {
    ...(compatible !== Compatible.all && selectedOfferIds?.length ? { selectedOfferIds } : {}),
    ...(providers?.length ? { providerActionAccounts: providers } : {}),
    ...filterOffers,
  };
};

export const getPreparedFilterCompute = (form: Form): TeeOfferFilter => {
  const { filters } = form || {};
  const {
    slots, options, lease,
  } = (filters || {}) as FormFiltersCompute;
  const { externalPort, traffic, bandwidth } = options || {};
  const { time } = lease || {};
  const { ram, diskUsage, cpuCores } = slots || {};
  return {
    ...defaultFilters,
    ...(externalPort === ExtPorts.yes ? { externalPort: externalPort ? 1 : 0 } : {}),
    ...(typeof cpuCores === 'number' ? { cpuCores: getPreparedRange(cpuCores) } : {}),
    ...(typeof ram === 'number' ? { ram: getPreparedRange(getBytesFromGb(ram)) } : {}),
    ...(typeof traffic === 'number' ? { traffic: getBytesFromGbit(traffic) } : {}),
    ...(typeof diskUsage === 'number' ? { diskUsage: getPreparedRange(getBytesFromGb(diskUsage)) } : {}),
    ...(typeof bandwidth === 'number' ? { bandwidth: getBytesFromMbit(bandwidth) } : {}),
    ...(typeof time === 'number' ? { usageMinutes: getMinutesFromHours(time) } : {}),
  };
};

export const getPreparedFilter = (
  form: Form,
  buildOrderForm?: BuildOrderForm,
  page?: OffersPages,
): OfferFilter | TeeOfferFilter => {
  switch (page) {
    case OffersPages.solutions:
      return {
        ...getPreparedFilterCommon(form),
        ...getPreparedFilterSolution(form, buildOrderForm),
      };
    case OffersPages.data:
      return {
        ...getPreparedFilterCommon(form),
        ...getPreparedFilterData(form, buildOrderForm),
      };
    case OffersPages.storage:
      return {
        ...getPreparedFilterCommon(form),
        ...getPreparedFilterStorage(form),
      };
    case OffersPages.compute:
      return {
        ...getPreparedFilterCompute(form),
      };
    default:
      return {
        ...getPreparedFilterCommon(form),
      };
  }
};

export const getPreparedSorting = (form: Form): ConnectionArgs => {
  switch (form?.[Blocks.controlPanel]?.[ControlPanelFields.sorting]) {
    case Sorting.newest:
      return { sort: [{ sortBy: 'origins.modifiedDate', sortDir: SortDir.Desc }] };
    case Sorting.availability:
      return {
        sort: [
          { sortBy: 'teeOffer.stats.ordersInQueue', sortDir: SortDir.Asc },
          { sortBy: 'price', sortDir: SortDir.Asc },
          { sortBy: 'slotTotalValues', sortDir: SortDir.Desc },
          { sortBy: 'optionTotalValues', sortDir: SortDir.Desc }],
      };
    default:
      return { sort: [{ sortBy: 'price', sortDir: SortDir.Asc }] };
  }
};

export const getPreparedFirst = (form: Form, active?: OffersPages): ConnectionArgs => {
  return {
    first: form?.[Blocks.controlPanel]?.[ControlPanelFields.size]
     ?? (active === OffersPages.compute ? null : 10),
  };
};

export const getPreparedPagination = (form: Form, active?: OffersPages): ConnectionArgs => ({
  ...getPreparedSorting(form),
  ...getPreparedFirst(form, active),
});

export const getPreparedForm = (form: Form, buildOrderForm?: BuildOrderForm, active?: OffersPages): Variables => ({
  filter: {
    ...getPreparedFilter(form, buildOrderForm, active),
  },
  pagination: {
    ...getPreparedPagination(form, active),
  },
});

export const getFiltersFromMinimalConfiguration = (configuration?: Omit<OfferConfiguration, 'vram'>): FormFiltersCompute => {
  if (!configuration) return {};
  const {
    cpuCores, ram, diskUsage, bandwidth, traffic, externalPort, minTimeMinutes,
  } = configuration || {};

  return {
    ...defaultFilters,
    [FiltersFields.slots]: {
      ...(cpuCores ? { cpuCores } : {}),
      ...(ram ? { ram: parseFloat(round(getGb(ram))) } : {}),
      ...(diskUsage ? { diskUsage: parseFloat(round(getGb(diskUsage))) } : {}),
    },
    [FiltersFields.options]: {
      ...(bandwidth ? { bandwidth: parseFloat(round(getMbit(bandwidth))) } : {}),
      ...(traffic ? { traffic: parseFloat(round(getGbit(traffic))) } : {}),
      externalPort: externalPort ? ExtPorts.yes : ExtPorts.any,
    },
    [FiltersFields.lease]: {
      ...(minTimeMinutes ? { time: Math.ceil(getHoursFromMinutes(minTimeMinutes)) } : {}),
    },
  };
};

export const getInitialState = (): FiltersState => ({
  [OffersPages.compute]: getInitialValues(OffersPages.compute),
  [OffersPages.storage]: getInitialValues(OffersPages.storage),
  [OffersPages.solutions]: getInitialValues(OffersPages.solutions),
  [OffersPages.data]: getInitialValues(OffersPages.data),
});
